import { z } from 'zod';

const SUCCESS_POST_MESSAGE = 'reauthentication-succeeded';
export const BROADCAST_CHANNEL_NAME = 'in-app-reauthentication';

interface SuccessPostMessage {
  message: typeof SUCCESS_POST_MESSAGE;
}

const reauthenticationSucceededSchema = z
  .object({
    origin: z.literal(window.location.origin),
    data: z.object({
      message: z.literal(SUCCESS_POST_MESSAGE),
    }),
  })
  .nonstrict();

export const isReauthenticationSucceededMessage = (
  event: MessageEvent
): event is MessageEvent<SuccessPostMessage> => {
  return reauthenticationSucceededSchema.safeParse(event).success;
};

/**
 * Posts a message from within the popup window back to the main window
 */
export const postReauthenticationSucceededMessage = () => {
  const bc = new BroadcastChannel(BROADCAST_CHANNEL_NAME);
  bc.postMessage({ message: SUCCESS_POST_MESSAGE });
};
