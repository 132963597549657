import Keycloak from 'keycloak-js';
import Service from './AuthService';

const keycloak = new Keycloak({
  url: import.meta.env.REACT_APP_KEYCLOAK_AUTH_ENDPOINT,
  realm: import.meta.env.REACT_APP_KEYCLOAK_AUTH_REALM || 'candis',
  clientId: import.meta.env.REACT_APP_KEYCLOAK_AUTH_CLIENT || 'candis',
});

keycloak.onTokenExpired = () => {
  console.log('token has expired');
  keycloak
    .updateToken(5)
    .then(() => console.log('token updated'))
    .catch(() => console.log('error updating token'));
};

export const AuthService = new Service(keycloak);
